<template>
  <LoginLayout>
    <div>
      <h3 class="font-heading text-6xl text-primary">reggie</h3>
      <h2 class="mt-2 font-subheading text-3xl text-gray-900">
        Sign into your account
      </h2>
    </div>

    <div class="mt-6">
      <el-form label-position="top">
        <el-form-item label="Email" prop="email" :error="validation.email">
          <el-input
            @keyup.enter="login()"
            v-model="form.email"
            type="email"
            size="large"
          />
        </el-form-item>
        <el-form-item
          @keyup.enter="login()"
          label="Password"
          prop="password"
          :error="validation.password"
        >
          <el-input v-model="form.password" type="password" size="large" />
        </el-form-item>
        <router-link
          class="text-sm font-medium text-primary hover:opacity-70"
          :to="{ name: 'forgot' }"
        >
          Forgot your password?
        </router-link>

        <div class="mt-4 space-y-2 text-center">
          <el-button
            @click="login()"
            class="w-full"
            type="primary"
            size="large"
            plain
            >Sign in</el-button
          >
          <p class="text-gray-400">or</p>
          <div class="flex gap-x-4">
            <SocialAuthButton
              platform="google"
              full="login"
              @click="googleAuth()"
            />
            <!-- <SocialAuthButton platform="facebook" @click="fbAuth()" /> -->
          </div>
        </div>
        <div class="mt-4 gap-x-5 text-sm">
          Or create your registry
          <router-link
            class="font-medium text-primary hover:opacity-70"
            :to="{ name: 'register' }"
          >
            here.
          </router-link>
        </div>
      </el-form>
    </div>
  </LoginLayout>
</template>

<script>
import LoginLayout from "@/layouts/Login.vue";
import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";
import SocialAuthButton from "@/components/ui/SocialAuthButton.vue";
export default {
  components: { LoginLayout, SocialAuthButton },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      validation: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    // Add listener to receive auth data from popup
    window.addEventListener("message", this.handleAuthReponse);
  },
  beforeUnmount() {
    // Remove the event listener when the component is about to be unmounted
    window.removeEventListener("message", this.handleAuthReponse);
  },
  methods: {
    ...mapActions(useUserStore, ["authenticateUser"]),
    login() {
      this.$axiosAuth
        .post("users/login/", this.form)
        .then((response) => {
          let user = {};
          user.email = response.data.user.email;
          user.pk = response.data.pk;
          user.username = response.data.username;
          user.email_verified = response.data.email_verified;
          user.start_datetime = response.data.start_datetime;
          user.host_name1 = response.data.host_name1;
          user.host_name2 = response.data.host_name2;
          this.authenticateUser(true, user);
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          this.validation = {
            email: null,
            password: null,
          };

          const errorMessages = error.response.data;
          errorMessages.email
            ? (this.validation.email = errorMessages.email[0])
            : (this.email = null);
          errorMessages.password
            ? (this.validation.password = errorMessages.password[0])
            : (this.password = null);
          errorMessages.non_field_errors
            ? (this.validation.password = errorMessages.non_field_errors[0])
            : (this.password = null);
        });
    },
    googleAuth() {
      const popupWidth = 500;
      const popupHeight = 600;
      const left = window.screen.width / 2 - popupWidth / 2;
      const top = window.screen.height / 2 - popupHeight / 2;
      const popupFeatures = `width=${popupWidth},height=${popupHeight},left=${left},top=${top},resizable,scrollbars`;
      const state = encodeURIComponent(
        window.btoa(JSON.stringify({ action: "login" }))
      );

      const popup = window.open(
        `https://accounts.google.com/o/oauth2/auth?client_id=${
          import.meta.env.VITE_GOOGLE_CLIENT_ID
        }&response_type=token&redirect_uri=${
          import.meta.env.VITE_GOOGLE_REDIRECT_URI
        }&scope=${import.meta.env.VITE_GOOGLE_SCOPE}&state=${state}`,
        "GoogleSignIn",
        popupFeatures
      );
    },
    handleAuthReponse(event) {
      if (!(event.origin === window.origin && "access_token" in event.data))
        return;
      const { access_token } = event.data;
      this.authenticateLogin(access_token);
    },
    authenticateLogin(access_token) {
      this.$axiosAuth
        .post("users/auth/google/?action=login", { access_token: access_token })
        .then((response) => {
          let user = {};
          user.email = response.data.user.email;
          user.pk = response.data.user.pk;
          user.username = response.data.user.username;
          user.email_verified = response.data.email_verified;
          user.start_datetime = response.data.start_datetime;
          user.host_name1 = response.data.host_name1;
          user.host_name2 = response.data.host_name2;
          this.authenticateUser(true, user);
          this.loading = false;
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          if (error.response.status === 403) {
            if (error.response.data.code === "user_already_exists") {
              this.loading = false;
              ElNotification({
                title: "Warning",
                message:
                  "You already have an account with us! Please login with your email and password.",
                type: "warning",
              });
              this.$router.push({ name: "login" });
            } else {
              this.loading = false;
              ElNotification({
                title: "Warning",
                message: "You haven't signed up yet! Let's get you started...",
                type: "warning",
              });
              this.$router.push({ name: "register" });
            }
          } else {
            this.loading = false;
            ElNotification({
              title: "Error",
              message: "Something went wrong.",
              type: "error",
            });
            this.$router.push({ name: "login" });
          }
        });
    },
  },
};
</script>

<!-- <div>
            <div>
              <p class="text-sm font-medium text-gray-700">
                Sign in with
              </p>

              <div class="mt-1 grid grid-cols-3 gap-3">
                <div>
                  <a
                    href="#"
                    class="
                      inline-flex
                      w-full
                      justify-center
                      rounded-md
                      border border-gray-300
                      bg-white
                      py-2
                      px-4
                      text-sm
                      font-medium
                      text-gray-500
                      shadow-sm
                      hover:bg-gray-50
                    "
                  >
                    <span class="sr-only">Sign in with Facebook</span>
                    <svg
                      class="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    class="
                      inline-flex
                      w-full
                      justify-center
                      rounded-md
                      border border-gray-300
                      bg-white
                      py-2
                      px-4
                      text-sm
                      font-medium
                      text-gray-500
                      shadow-sm
                      hover:bg-gray-50
                    "
                  >
                    <span class="sr-only">Sign in with Twitter</span>
                    <svg
                      class="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                      />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    class="
                      inline-flex
                      w-full
                      justify-center
                      rounded-md
                      border border-gray-300
                      bg-white
                      py-2
                      px-4
                      text-sm
                      font-medium
                      text-gray-500
                      shadow-sm
                      hover:bg-gray-50
                    "
                  >
                    <span class="sr-only">Sign in with GitHub</span>
                    <svg
                      class="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div class="relative mt-6">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="bg-white px-2 text-gray-500"
                  >Or continue with</span
                >
              </div>
            </div>
          </div> -->
