<template>
  <div class="register">
    <div
      class="flex min-h-screen flex-col items-center justify-between bg-secondary px-4 pb-6 pt-4 sm:px-6 lg:px-8"
    >
      <h3 class="text-center text-5xl text-primary first-line:font-heading">
        reggie
      </h3>
      <div class="w-screen px-6 lg:w-[620px] lg:px-0">
        <Transition name="slide-up" mode="out-in" appear>
          <div
            :key="currentQuestion.question"
            class="flex w-full flex-col items-center space-y-4 lg:space-y-8"
          >
            <Transition name="slide-up" mode="out-in" appear>
              <div v-if="currentQuestion.showRegistry">
                <Split_2Layout
                  :event="eventForm"
                  :dashboardPreview="true"
                  class="rounded-t-lg"
                />
                <!-- <CustomFieldsPreview :event="eventForm" :dashboardPreview="true" /> -->
                <GiftsPreview
                  class="rounded-b-lg"
                  :registration="true"
                  :countryCode="eventForm.country_code"
                  :giftTemplates="gift_templates"
                />
                <div class="mt-4 text-center">
                  <el-button
                    type="primary"
                    size="large"
                    plain
                    round
                    @click="questionCounter++"
                    >Next</el-button
                  >
                </div>
              </div>
            </Transition>
            <Transition name="slide-up" mode="out-in">
              <!-- This is the question on each page (and the first welcome question) -->
              <div
                v-if="!showingMessage"
                :class="questionCounter == 0 ? 'self-center' : 'self-start'"
                class="mt-6 text-center text-2xl tracking-wide text-gray-900 lg:self-center lg:text-3xl"
              >
                <h2
                  v-html="currentQuestion.question"
                  class="font-subheading"
                ></h2>
                <h2
                  v-html="currentQuestion.extra"
                  v-if="currentQuestion.extra && !showingMessage"
                  class="mt-1 text-lg text-slate-500"
                ></h2>
              </div>

              <!-- This is the message between questions -->
              <h2
                v-else-if="showingMessage"
                v-html="currentQuestion.message"
                class="mt-6 text-center font-subheading text-2xl text-gray-900 lg:text-3xl"
              ></h2>
            </Transition>

            <Transition name="slide-up" mode="out-in">
              <div
                v-if="currentQuestion.inputs && !showingMessage"
                class="input-box"
              >
                <!-- Only show this part on the last registration page -->
                <div v-if="currentQuestion == questions[questions.length - 1]">
                  <div class="pb-3">
                    <div
                      class="flex gap-x-4"
                      :class="registering ? 'opacity-0' : null"
                    >
                      <SocialAuthButton
                        platform="google"
                        type="register"
                        @click="googleAuth()"
                      />
                      <!-- <SocialAuthButton platform="facebook" @click="fbAuth()" /> -->
                    </div>
                  </div>
                  <div class="relative">
                    <div
                      class="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div class="w-full border-t border-gray-300" />
                    </div>
                    <div class="relative flex justify-center text-sm">
                      <span class="bg-secondary px-2 text-gray-500"
                        >Or register with</span
                      >
                    </div>
                  </div>
                </div>
                <template
                  v-for="(input, index) in currentQuestion.inputs"
                  :key="index"
                >
                  <div v-if="input.type == 'date'">
                    <el-form-item>
                      <p
                        class="el-form-item__label"
                        style="justify-content: flex-start"
                      >
                        {{ input.label }}
                      </p>
                      <div class="mt-1 w-full">
                        <el-date-picker
                          v-model="eventForm.start_datetime"
                          type="date"
                          size="large"
                          :clearable="false"
                          placeholder="Pick a Date"
                          :format="dateFormatCode"
                          :disabled-date="
                            function (date) {
                              return date < Date.now();
                            }
                          "
                        />
                      </div>
                      <span
                        class="inline-block pl-1 text-slate-500"
                        v-if="input.extra"
                      >
                        {{ input.extra }}</span
                      >
                    </el-form-item>
                  </div>
                  <div v-else-if="input.type === 'countrySelect'">
                    <el-form-item>
                      <p
                        class="el-form-item__label"
                        style="justify-content: flex-start"
                      >
                        {{ input.label }}
                      </p>
                      <el-select
                        v-model="eventForm.country_code"
                        :filterable="!userMobile"
                        class="w-full"
                        size="large"
                      >
                        <el-option
                          v-for="item in countryDropdownOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                          <span style="float: left">{{ item.label }}</span>
                          <span style="float: right"
                            ><country-flag
                              :country="item.value.toLowerCase()"
                              size="small"
                          /></span>
                        </el-option>
                      </el-select>
                      <span
                        class="inline-block pl-1 text-slate-500"
                        v-if="input.extra"
                      >
                        {{ input.extra }}</span
                      >
                    </el-form-item>
                  </div>
                  <div v-else-if="input.type == 'image'">
                    <div>
                      <el-radio-group
                        v-model="eventForm.properties.img_source"
                        class="mb-4"
                      >
                        <el-radio-button
                          class="capitalize"
                          v-for="option in imageOptions"
                          :key="option.key"
                          :label="option.key"
                          >{{ option.name }}</el-radio-button
                        >
                      </el-radio-group>

                      <div class="flex gap-x-2">
                        <ImageUpload
                          class="flex-1"
                          v-if="eventForm.properties.img_source == 'img'"
                          @imageUploaded="
                            (file) => {
                              handleImage(file[0], 'eventForm');
                            }
                          "
                        />
                        <div
                          v-if="
                            eventForm.imgPreview &&
                            eventForm.properties.img_source == 'img'
                          "
                          class="w-1/2"
                        >
                          <img class="w-full" :src="eventForm.imgPreview" />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="eventForm.properties.img_source == 'img_url_stock'"
                    >
                      <div>
                        <ImageGallerySearch
                          :registration="true"
                          @imageSelected="
                            (url) => {
                              handleStockImage(url, 'eventForm');
                            }
                          "
                        />
                      </div>
                    </div>
                    <div v-if="eventForm.properties.img_source == 'img_url'">
                      <p class="mb-2 text-sm font-medium text-gray-900">
                        Paste the link to an image below
                      </p>
                      <el-input
                        v-model="eventForm.img_url"
                        type="text"
                        size="large"
                      />
                    </div>
                  </div>
                  <div v-else-if="input.type == 'gift'">
                    <GiftTemplateComponent
                      :countryCode="eventForm.country_code"
                      endpoint="public"
                      :selectedGiftIds="eventForm.gift_template_ids"
                      @addGift="(gifts) => (gift_templates = gifts)"
                    />
                  </div>
                  <div v-else-if="input.type == 'url'">
                    <el-form-item>
                      <p
                        class="el-form-item__label"
                        style="justify-content: flex-start"
                      >
                        {{ input.label }}
                      </p>
                      <el-input
                        class="url-input"
                        @input="cleanAndCheckDomain()"
                        v-model="eventForm[input.formLabel]"
                        :placeholder="input.placeholder"
                        size="large"
                        :maxlength="maxDomainLength"
                      >
                        <template #prefix>
                          <span>lovereggie.com/</span>
                        </template>
                      </el-input>
                      <span
                        class="inline-block pl-1 text-slate-500"
                        v-if="input.extra"
                      >
                        {{ input.extra }}</span
                      >
                    </el-form-item>

                    <el-alert
                      v-if="!domainAvailable"
                      :title="getDomainErrorText()"
                      type="error"
                      :closable="false"
                      effect="dark"
                    />
                  </div>
                  <template v-else>
                    <el-form-item
                      v-if="input.form && input.form == 'user'"
                      :error="input.validation"
                    >
                      <p
                        class="el-form-item__label"
                        style="justify-content: flex-start"
                      >
                        {{ input.label }}
                      </p>
                      <el-input
                        v-model="userForm[input.formLabel]"
                        size="large"
                        :label="input.label"
                        :placeholder="input.placeholder"
                        :type="input.type"
                      />
                    </el-form-item>

                    <el-form-item v-else>
                      <p
                        class="el-form-item__label"
                        style="justify-content: flex-start"
                      >
                        {{ input.label }}
                      </p>
                      <el-input
                        v-model="eventForm[input.formLabel]"
                        size="large"
                        :label="input.label"
                        :placeholder="input.placeholder"
                        :type="input.type"
                      />
                    </el-form-item>
                  </template>
                </template>
              </div>
            </Transition>
            <Transition name="slide-up" mode="out-in">
              <div v-if="!currentQuestion.showRegistry && !showingMessage">
                <el-button
                  :loading="loading"
                  :disabled="disableNext"
                  type="primary"
                  size="large"
                  round
                  plain
                  @click="nextQuestion()"
                  ><span class="text-lg">
                    {{
                      currentQuestion.button && currentQuestion.button.text
                        ? currentQuestion.button.text
                        : "Next"
                    }}
                  </span></el-button
                >
              </div>
            </Transition>
          </div>
        </Transition>
      </div>
      <Steps
        v-if="showSteps"
        class="mt-6 lg:mt-0"
        :class="registering ? 'opacity-0' : null"
        :total="questions.length"
        :current="questionCounter"
        @change-step="(e) => (questionCounter = e)"
      />
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";
import Steps from "@/components/public/Steps.vue";
import Split_2Layout from "@/layouts/event/Split_2.vue";
import GiftsPreview from "@/components/public/GiftsPreview.vue";
import CustomFieldsPreview from "@/components/public/CustomFieldsPreview.vue";
import URLInput from "@/components/public/URLInput.vue";
import SocialAuthButton from "@/components/ui/SocialAuthButton.vue";
import ImageUpload from "@/components/admin/ImageUpload.vue";
import ImageGallerySearch from "@/components/admin/ImageGallerySearch.vue";
import GiftTemplateComponent from "@/components/admin/GiftTemplateComponent.vue";
import ImageUploadMixin from "@/mixins/ImageUpload.js";
import CountryFlag from "vue-country-flag-next";

export default {
  components: {
    Steps,
    Split_2Layout,
    GiftsPreview,
    CustomFieldsPreview,
    URLInput,
    SocialAuthButton,
    ImageUpload,
    ImageGallerySearch,
    GiftTemplateComponent,
    CountryFlag,
  },

  mixins: [ImageUploadMixin],

  mounted() {
    //get query string called ref
    if (this.$route.query.ref) {
      //create a cookie and assign value to cookie to expire in 30 days from now
      let date = new Date();
      date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
      $cookies.set("ref", this.$route.query.ref, date);
    }

    //get cookie called ref (this will either be GADW set from marketing site or ref set above for affiliates)
    if ($cookies.get("ref")) {
      const referrer = $cookies.get("ref");
      this.eventForm.referred_by = referrer;
      if (referrer === "sayido") {
        this.eventForm.properties.showCustomFields = false;
      }
    }

    window.addEventListener("keyup", (event) => {
      if (
        event.keyCode === 13 &&
        !this.disableNext &&
        !this.userMobile &&
        this.questionCounter != 3 &&
        this.questionCounter != 4
      ) {
        this.nextQuestion();
      }
    });
    if (this.userMobile) {
      this.questions.splice(3, 3);
    }
    this.showSteps = true;
    // Add listener to receive auth data from popup
    window.addEventListener("message", this.handleAuthReponse);
  },
  beforeUnmount() {
    // Remove the event listener when the component is about to be unmounted
    window.removeEventListener("message", this.handleAuthReponse);
  },
  data() {
    return {
      questionCounter: 0,
      showSteps: false,
      maxDomainLength: 20,
      questions: [
        {
          question:
            "Let's setup your very own wedding registry. <p class='mt-2'>It will only take a few minutes.</p>",
          button: { text: "Get started" },
          completed: false,
        },
        {
          question: "What are your names?",
          message:
            "So apparently you two are getting married? 💍<p class='mt-2'> A big congrats from us! 🍻</p>",

          inputs: [
            {
              label: "Your First Name",
              formLabel: "name_host1",
              required: true,
              form: "event",
            },
            {
              label: "Your Partner's First Name",
              formLabel: "name_host2",
              required: true,
              form: "event",
            },
          ],
          completed: false,
        },
        {
          question: "A few more details...",
          inputs: [
            {
              label: "Where do you live?",
              formLabel: "country_code",
              extra: "We will use this to set the currency of your registry.",
              form: "event",
              required: true,
              type: "countrySelect",
            },
            {
              label: "When is the big day?",
              extra: "No date yet? You can leave this empty.",
              formLabel: "start_datetime:",
              type: "date",
              form: "event",
            },
            {
              label: "Choose a URL for your registry",
              extra: "Don't worry, you can change this later.",
              formLabel: "domain_name",
              type: "url",
              required: true,
              form: "event",
              available: this.domainAvailable,
            },
          ],
          completed: false,
        },
        {
          question: "Add an image to your registry",
          extra:
            "You can upload an image or search for one in our image gallery. <br /> Feel free to skip this now, you can add one later.",
          inputs: [
            {
              label: "",
              type: "image",
              form: "event",
            },
          ],
          completed: false,
        },
        {
          question: "Now add some gifts!",
          extra:
            "Find the perfect gifts below using the tags and search box. You can skip this now and do it later.",
          message: "Now for a sneak peek of your registry. 🫣",
          inputs: [
            {
              label: "",
              type: "gift",
              form: "event",
            },
          ],
          completed: false,
        },
        {
          showRegistry: true,
          // message: "That looks great, nice work!",
          completed: false,
        },
        {
          question: "Last few details and we're done. 😊",
          inputs: [
            {
              label: "Your email address",
              formLabel: "email",
              type: "email",
              form: "user",
              required: true,
            },
            {
              label: "A secure password",
              formLabel: "password1",
              type: "password",
              form: "user",
              required: true,
            },
            {
              label: "You know the drill... retype your password",
              formLabel: "password2",
              type: "password",
              form: "user",
              required: true,
            },
          ],
          button: {
            submit: true,
            text: "Register",
            disabled: !this.domainAvailable,
          },
          completed: false,
        },
      ],
      showingMessage: false,
      eventForm: {
        description:
          "Your wedding registry is already looking amazing! You will be able to make more edits after you create your account.",
        properties: {
          layout: "split 2",
          font: "Young Serif",
          fontAll: true,
          clipPath: "circle(50% at 50% 50%)",
          animate: true,
          img_source: "img_url_stock",
          show_date: true,
          showCustomFields: true,
          customFieldsTitle: "Details",
          moveFundedGiftEnd: true,
          notifications: {
            contribution: true,
            giftFunded: true,
          },
          colors: {
            accent: "#DAE7E7",
            background: "#14281D",
            heading: "#DAE7E7",
            paragraph: "#DAE7E7",
            additionalDetails: "#DAE7E7",
          },
        },
        img_url_stock:
          "https://images.unsplash.com/photo-1491582990992-68ec88e070a3?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0MjA5Mjd8MHwxfHNlYXJjaHwyfHxoYXBweSUyMGdheSUyMGNvdXBsZXxlbnwwfHx8fDE3MDg4MzU4MzJ8MA&ixlib=rb-4.0.3",
        countryOptions: [],
        custom_user_fields: [
          {
            title: "Time",
            description:
              "The ceremony starts at 1pm, followed by a party till the sun goes down.",
            icon: "Alarm_Clock",
          },
          {
            title: "Location",
            description:
              "100A The Moon Road, The Moon, The Milkyway. Enter via the moon.",
            icon: "Map_Marker",
          },
          {
            title: "Dress Code",
            description:
              "Smart casual, business, black tie, or birthday suit; we don't care as long as you're on time.",
            icon: "Coat_Hanger",
          },
          {
            title: "More more more!",
            description:
              "Feel free to add as many details as you like. Just click the 'Add New Card' button at the top.",
            icon: "Pool",
          },
        ],
        gift_template_ids: [],
      },
      gift_templates: [],
      userForm: {},
      loading: false,
      registering: false,
      domainAvailable: null,
      domainResponse: null,
      imgPreview: null,
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.questionCounter];
    },
    disableNext() {
      if (!this.currentQuestion.inputs) return;

      if (this.questionCounter === 2 && !this.domainAvailable) return true;

      if (
        !this.userMobile &&
        this.questionCounter == 3 &&
        this.eventForm.img_url == undefined &&
        this.eventForm.img == undefined &&
        this.eventForm.img_url_stock == undefined
      )
        return true;

      return this.currentQuestion.inputs.some((input) => {
        let form = input.form + "Form";
        return (
          (input.required && this[form][input.formLabel] == undefined) ||
          (this[form][input.formLabel] == "" &&
            this[form].button &&
            this[form].button.disabled)
        );
      });
    },
  },
  watch: {
    questionCounter(val) {
      if (val == 2) {
        this.eventForm.name_host1 = this.capitalize(
          this.eventForm.name_host1.trim()
        );
        this.eventForm.name_host2 = this.capitalize(
          this.eventForm.name_host2.trim()
        );
        let slug =
          this.eventForm.name_host1 + "and" + this.eventForm.name_host2;
        this.eventForm.domain_name = slug.toLowerCase();
        this.cleanAndCheckDomain();
      }
      // show stock image if user clicks image picker but doesn't add anything
      if (
        this.eventForm.img_url == undefined &&
        this.eventForm.img == undefined
      ) {
        this.eventForm.properties.img_source = "img_url_stock";
      }
    },
    gift_templates() {
      this.eventForm.gift_template_ids = this.gift_templates.map(
        (gift) => gift.template_id
      );
    },
  },
  methods: {
    ...mapActions(useUserStore, ["authenticateUser"]),
    nextQuestion() {
      if (
        this.currentQuestion.message != undefined &&
        !this.currentQuestion.completed
      ) {
        this.showingMessage = true;
        setTimeout(() => {
          this.showingMessage = false;
          this.currentQuestion.completed = true;
          this.questionCounter++;
        }, 3000);
      } else {
        this.currentQuestion.button && this.currentQuestion.button.submit
          ? this.registerUser()
          : this.questionCounter++;
      }
    },
    cleanAndCheckDomain() {
      this.eventForm.domain_name = this.eventForm.domain_name.replace(
        /[^A-Za-z0-9\-_.~]/g,
        ""
      ); //remove un-safe URL chars
      if (this.eventForm.domain_name.length > this.maxDomainLength)
        this.eventForm.domain_name = this.eventForm.domain_name.slice(
          0,
          this.maxDomainLength
        ); // reduce to 20 or les chars
      this.checkDomainAvailable(this.eventForm.domain_name);
    },
    getDomainErrorText() {
      if (this.domainResponse) {
        return `${this.domainResponse.detail}`;
      }
    },
    registerUser() {
      this.loading = true;
      this.$axios
        .post("users/register/", this.userForm)
        .then((response) => {
          this.loginUser();
        })
        .catch((error) => {
          //loop through last question in inputs and add any errors for validation
          let registrationQuestion = this.questions[this.questions.length - 1];
          let errors = error.response.data;
          registrationQuestion.inputs.forEach((input) => {
            input.validation = "";
            Object.keys(errors).forEach(function (key) {
              if (input.formLabel == key) {
                input.validation = errors[key][0];
              }
              //Django returns the password don't match error as a different key, handle here
              if (key == "non_field_errors" && input.formLabel == "password2") {
                input.validation = errors[key][0];
              }
            });
          });
          this.loading = false;
        });
    },
    loginUser() {
      this.$axiosAuth
        .post("users/login/", {
          email: this.userForm.email,
          password: this.userForm.password1,
        })
        .then((response) => {
          this.createEvent(response);
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong",
            type: "error",
          });
          this.loading = false;
        });
    },
    createEvent(userResponse) {
      if (!("social_signup" in this.eventForm.properties))
        this.eventForm.properties.social_signup = false; // if not included from social auth then set to false

      this.eventForm.description = "";
      this.eventForm.properties.showGiftPrices = true;

      if (this.eventForm.start_datetime instanceof Object) {
        this.eventForm.start_datetime =
          this.eventForm.start_datetime.toISOString(); //change format for upload
      }

      let formData = new FormData();

      for (const item in this.eventForm) {
        let toAppend = this.eventForm[item];
        if (
          item === "properties" ||
          item === "custom_user_fields" ||
          item === "gift_template_ids"
        ) {
          toAppend = JSON.stringify(toAppend);
        }
        formData.append(`${item}`, toAppend);
      }

      this.$axiosAuth
        .post("host/event/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          // now that user and event are created, persist data to Pinia
          let user = {};
          //first the user data passed into this function
          user.email = userResponse.data.user.email;
          user.pk = userResponse.data.user.pk;
          user.username = userResponse.data.user.username;
          user.email_verified = userResponse.data.email_verified;

          //then the  data from the event creation
          user.start_datetime = response.data.start_datetime;
          user.host_name1 = response.data.name_host1;
          user.host_name2 = response.data.name_host2;

          // now add to Pinia
          this.authenticateUser(true, user);

          gtag("event", "user_registered");

          ElNotification({
            title: "Success",
            message: "Nice, we are creating your registry for you now!",
            type: "success",
          });
          this.registering = true;
          setTimeout(() => {
            this.$router.push({ name: "dashboard" });
          }, 3500);
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong, please contact us for help",
            type: "error",
          });
          this.loading = false;
        });
    },

    handleAuthReponse(event) {
      if (!(event.origin === window.origin && "access_token" in event.data))
        return;
      const { eventFormDecoded, access_token } = event.data;
      this.evenForm = eventFormDecoded;
      this.authenticateRegister(access_token);
    },

    authenticateRegister(access_token) {
      this.$axiosAuth
        .post("users/auth/google/", { access_token: access_token })
        .then((response) => {
          this.createEvent(response);
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.data.code === "user_already_exists"
          ) {
            this.loading = false;
            ElNotification({
              title: "Warning",
              message:
                "This email is already taken. Please register with a different account or login.",
              type: "warning",
            });
          } else {
            this.loading = false;
            ElNotification({
              title: "Error",
              message: "Something went wrong.",
              type: "error",
            });
            this.$router.push({ name: "register" });
          }
        });
    },

    googleAuth() {
      this.eventForm.properties.social_signup = true;

      //change datetime format before stringify for social sign up
      if (
        this.eventForm.start_datetime &&
        this.eventForm.start_datetime instanceof Date
      ) {
        this.eventForm.start_datetime =
          this.eventForm.start_datetime.toISOString();
      } else {
        this.eventForm.start_datetime = "";
      }

      const popupWidth = 500;
      const popupHeight = 600;
      const left = window.screen.width / 2 - popupWidth / 2;
      const top = window.screen.height / 2 - popupHeight / 2;
      const popupFeatures = `width=${popupWidth},height=${popupHeight},left=${left},top=${top},resizable,scrollbars`;

      //If an image needs to be uploaded then first upload image to temp spot on server
      if (this.eventForm.img) {
        let formData = new FormData();
        formData.append("image", this.eventForm.img);
        this.$axios
          .post("upload-register-image/", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            //once uploaded proceed with auth
            this.eventForm.imgToUpload = response.data.filename; //put filename here for social account registration

            let state = encodeURIComponent(
              window.btoa(JSON.stringify(this.eventForm))
            );
            const popup = window.open(
              `https://accounts.google.com/o/oauth2/auth?client_id=${
                import.meta.env.VITE_GOOGLE_CLIENT_ID
              }&response_type=token&redirect_uri=${
                import.meta.env.VITE_GOOGLE_REDIRECT_URI
              }&scope=${import.meta.env.VITE_GOOGLE_SCOPE}&state=${state}`,
              "GoogleSignIn",
              popupFeatures
            );
          })
          .catch((error) => {
            ElNotification({
              title: "Error",
              message: "Something went wrong",
              type: "error",
            });
          });
      } else {
        let state = encodeURIComponent(
          window.btoa(JSON.stringify(this.eventForm))
        );
        const popup = window.open(
          `https://accounts.google.com/o/oauth2/auth?client_id=${
            import.meta.env.VITE_GOOGLE_CLIENT_ID
          }&response_type=token&redirect_uri=${
            import.meta.env.VITE_GOOGLE_REDIRECT_URI
          }&scope=${import.meta.env.VITE_GOOGLE_SCOPE}&state=${state}`,
          "GoogleSignIn",
          popupFeatures
        );
      }
    },
  },
};
</script>

<style>
.register {
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: all 0.25s ease-out;
  }

  .slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
  }

  .slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }

  .input-box {
    @apply max-h-[700px] w-full space-y-1 overflow-auto text-left lg:rounded-xl lg:border lg:border-[rgb(239,241,244)] lg:p-8 lg:shadow-[0px_5px_20px_rgb(0,0,0,.15)]  !important;
  }

  .el-date-editor {
    @apply w-full !important;
  }

  .el-form-item {
    @apply mb-1;
  }

  .el-select__input:focus {
    box-shadow: none;
  }
}
</style>
