<template>
  <div class="2xl:px-36">
    <div class="flex items-start gap-x-4">
      <h2
        :data-aos="event.properties.animate ? 'fade-in' : null"
        data-aos-once="true"
        data-aos-duration="1000"
        class="mb-6 text-3xl leading-10 tracking-tight text-gray-900 lg:mb-10"
        :style="
          event.properties.fontAll
            ? {
                fontFamily: event.properties.font,
              }
            : null
        "
      >
        Gifts
      </h2>
    </div>

    <!-- Showcase gift -->
    <ul role="list" class="mb-8 md:mb-16">
      <li
        v-show="gifts.length > 0 && pinnedGift.length > 0"
        v-for="gift in pinnedGift"
        :key="gift.gift_id"
        :id="gift.gift_id"
        :data-aos="event.properties.animate ? 'fade-in' : null"
        data-aos-once="true"
        data-aos-duration="1000"
      >
        <component
          :is="gift.is_funded ? 'span' : 'router-link'"
          :to="{ name: routeName, params: { giftId: gift.gift_id } }"
          class="relative grid grid-cols-1 gap-x-0 md:grid-cols-2 md:gap-x-16"
        >
          <ShowcaseGift
            :gift="gift"
            :showPrices="event.properties.showGiftPrices"
          />
        </component>
      </li>
    </ul>

    <!-- All other gifts -->
    <ul
      role="list"
      class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-6"
    >
      <li
        v-for="gift in gifts"
        :key="gift.gift_id"
        :id="gift.gift_id"
        v-show="!gift.properties.pinned"
        class="relative"
        :data-aos="event.properties.animate ? 'fade-in' : null"
        data-aos-once="true"
        data-aos-duration="1000"
      >
        <component
          :is="gift.is_funded ? 'span' : 'router-link'"
          :to="{ name: routeName, params: { giftId: gift.gift_id } }"
        >
          <SingleGift
            :gift="gift"
            :showPrices="event.properties.showGiftPrices"
            :admin="false"
          />
        </component>
      </li>
    </ul>
  </div>
</template>

<script>
import SingleGift from "@/components/admin/SingleGift.vue";
import ShowcaseGift from "@/components/admin/ShowcaseGift.vue";

export default {
  components: { SingleGift, ShowcaseGift },
  props: ["gifts", "event", "preview"],
  computed: {
    routeName() {
      return this.preview ? "gift-preview" : "gift";
    },

    pinnedGift() {
      return this.gifts.filter((gift) => gift.properties.pinned);
    },
  },
};
</script>
