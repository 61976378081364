<template>
  <div class="gift-template-component">
    <div v-if="!showSelected" class="mb-4 flex space-x-4">
      <el-input v-model="search" placeholder="Search for a gift" />
      <el-button type="primary" size="large" plain @click="clearFilters"
        >Clear</el-button
      >
    </div>
    <div v-else class="mb-3 font-bold">Selected Gifts</div>
    <el-scrollbar v-if="!showSelected">
      <div class="mb-4 flex space-x-2">
        <el-check-tag
          v-for="(tag, index) in tags"
          :checked="tag.checked"
          @change="tag.checked = !tag.checked"
          :key="tag.name"
          type="primary"
        >
          {{ tag.name }}
        </el-check-tag>
      </div>
    </el-scrollbar>

    <ul
      role="list"
      class="mt-2 grid grid-cols-1 gap-x-4 gap-y-4 overflow-y-auto p-1 lg:grid-cols-3 xl:gap-x-6"
      :class="endpoint == 'public' ? 'h-72' : 'h-96'"
    >
      <!-- on onboarding, no gifts selected, show button to add gift -->
      <div
        v-if="endpoint == 'public' && showSelected && selectedGifts.length == 0"
        @click="showSelected = false"
        class="cursor-pointer"
      >
        <div
          class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 px-2 py-6 text-center focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="{1.5}"
            stroke="currentColor"
            class="mx-auto h-12 w-12 text-red-400"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
            />
          </svg>

          <span class="mt-2 block text-xs font-medium text-gray-900"
            >No gifts selected. Click to add your first gift.</span
          >
        </div>
      </div>
      <!-- otherwise show gifts -->
      <li
        v-for="gift in showSelected ? selectedGifts : filteredGifts"
        :key="gift.template_id"
        class="relative"
        @click="selectGifts(gift)"
      >
        <div
          class="group block aspect-square overflow-hidden rounded-lg border border-gray-100 bg-gray-100"
          :class="gift.selected ? 'ring-2 ring-red-500 ring-offset-2' : ' '"
        >
          <img
            :src="giftImageUrl(gift, 'small', true)"
            alt=""
            class="h-full w-full cursor-pointer object-cover group-hover:opacity-75"
          />
        </div>

        <div class="mt-2 text-sm text-gray-900">
          <div>{{ gift.brand ? `${gift.brand} ` : "" }}{{ gift.name }}</div>
          <div v-if="gift.amount_fmt > 0 && endpoint == 'host'">
            {{ formatCurrency(gift.amount_fmt, countryCode, currency) }}
          </div>
        </div>
      </li>
    </ul>

    <div
      v-if="endpoint == 'host'"
      class="mt-6 flex w-full flex-col-reverse gap-y-2 lg:flex-row lg:justify-between lg:gap-y-0"
    >
      <div class="text-lg">
        <el-button type="primary" class="w-full" text bg @click="emitEmptyGift">
          <el-icon class="mr-1"><Present /></el-icon>Or add your own gift
        </el-button>
      </div>
      <div class="space-x-2">
        <el-button v-if="!userMobile" @click="showModal = false"
          >Cancel</el-button
        >
        <el-button
          class="w-full lg:w-auto"
          type="primary"
          @click="emitSelectedGifts"
        >
          Select
        </el-button>
      </div>
    </div>
    <div v-if="endpoint == 'public'" class="-mb-4 mt-6 flex w-full justify-end">
      <el-switch
        v-model="showSelected"
        active-text="Show Selected Gifts"
      ></el-switch>
    </div>
  </div>
</template>

<script>
import { Present } from "@element-plus/icons-vue";

export default {
  data() {
    return {
      giftTemplates: [],
      tags: [],
      search: "",
      showSelected: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axiosAuth
        .get(`${this.endpoint}/gift-templates/?country=${this.countryCode}`)
        .then((res) => {
          this.giftTemplates = res.data;
          this.reselectGifts();
          this.currency = res.data[0].converted_currency;
        });
      this.$axiosAuth.get(`${this.endpoint}/tags/`).then((res) => {
        this.tags = res.data.map((tag) => {
          return { name: tag.name, checked: false };
        });
      });
    },
    reselectGifts() {
      if (this.selectedGiftIds && this.selectedGiftIds.length > 0) {
        this.giftTemplates.forEach((gift) => {
          if (this.selectedGiftIds.includes(gift.template_id)) {
            gift.selected = true;
          }
        });
      }
    },
    clearFilters() {
      this.search = "";
      this.tags.forEach((tag) => (tag.checked = false));
    },
    selectGifts(gift) {
      if (this.endpoint == "public") {
        !gift.selected ? (gift.selected = true) : (gift.selected = false);
        this.emitSelectedGifts();
      } else {
        this.clearSelectedGifts();
        gift.selected = true;
      }
    },
    clearSelectedGifts() {
      this.giftTemplates.forEach((gift) => (gift.selected = false));
    },
    emitSelectedGifts() {
      //on registration flow we emit all selected gifts as we allow bulk select, in app, just emit one selected gift.
      if (this.endpoint == "public") {
        this.$emit("addGift", this.selectedGifts);
      } else {
        this.$emit("addGift", this.selectedGifts[0]);
        this.clearFilters();
        this.clearSelectedGifts();
      }
    },
    emitEmptyGift() {
      this.$emit("addGift", null);
    },
  },
  computed: {
    filteredGifts() {
      // Get selected tag names
      const selectedTagNames = this.tags
        .filter((tag) => tag.checked)
        .map((tag) => tag.name);

      // Check if any tags are selected
      const anyTagsSelected = selectedTagNames.length > 0;

      // Filter gifts based on selected tags and search text
      return this.giftTemplates.filter((gift) => {
        // Check if the gift name or brand contains the search text
        const matchesSearchText =
          gift.name.toLowerCase().includes(this.search.toLowerCase()) ||
          (gift.brand &&
            gift.brand.toLowerCase().includes(this.search.toLowerCase()));

        // If no tags are selected, return gifts that match the search text
        if (!anyTagsSelected) {
          return matchesSearchText;
        }

        // Check if any of the selected tags match the tags of the gift
        const matchesTags = selectedTagNames.some((tagName) =>
          gift.tags.includes(tagName)
        );

        // Return true if the gift matches the search text and any of the selected tags
        return matchesSearchText && matchesTags;
      });
    },
    selectedGifts() {
      //show all gifts, ignore filters
      return this.giftTemplates.filter((gift) => gift.selected);
    },
  },

  props: ["countryCode", "endpoint", "selectedGiftIds"],
  emits: ["addGift"],
  components: {
    Present,
  },
};
</script>

<style>
.gift-template-component {
  .el-scrollbar {
    @apply w-[calc(100%+2rem)];
  }
}
</style>
