<template>
  <!-- <el-button type="primary" size="large" round @click="showModal = true"
    >Add New Gift</el-button
  > -->
  <el-dropdown>
    <el-button type="primary">
      Add New Gift<el-icon class="el-icon--right"><arrow-down /></el-icon>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="showModal = true"
          >Search for a gift</el-dropdown-item
        >
        <el-dropdown-item divided @click="$emit('add-gift', null)"
          >Add a custom gift</el-dropdown-item
        >
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <el-dialog
    v-model="showModal"
    title="Gifts"
    width="800"
    :fullscreen="userMobile"
    @close="handleClose"
  >
    <div v-if="!event.properties.closeGiftAlert" class="mb-4">
      <el-alert
        title="💰 Cash Registry Reminder 💰"
        type="info"
        @close="patchEvent()"
        >We deliver the cash to your bank account, not the gift to your
        doorstep.
      </el-alert>
    </div>

    <GiftTemplateComponent
      @add-gift="(gift) => ($emit('add-gift', gift), (this.showModal = false))"
      :countryCode="countryCode"
      endpoint="host"
    />
  </el-dialog>
</template>

<script>
import GiftTemplateComponent from "@/components/admin/GiftTemplateComponent.vue";
import { ArrowDown } from "@element-plus/icons-vue";

export default {
  data() {
    return {
      showModal: false,
    };
  },
  components: {
    GiftTemplateComponent,
    ArrowDown,
  },
  props: ["countryCode", "showModalProp", "event"],
  emits: ["addGift", "modal-closed"],
  methods: {
    handleClose() {
      this.$emit("modal-closed");
    },
    patchEvent() {
      this.event.properties.closeGiftAlert = true;
      this.$axiosAuth.patch(`host/event/${this.event.event_id}/`, {
        properties: this.event.properties,
      });
    },
  },
  watch: {
    showModalProp: {
      handler(val) {
        this.showModal = val;
      },
    },
  },
};
</script>

<style></style>
