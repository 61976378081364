<template>
  <div class="group relative cursor-pointer">
    <div
      v-if="admin && editingGiftOrder"
      class="drag absolute right-7 top-1/2 z-[8] w-6 -translate-y-1/2 cursor-move"
    >
      <Bars3Icon class="h-12 w-12 text-black" aria-hidden="true" />
    </div>
    <el-tooltip
      v-if="admin && !gift.draft && !editingGiftOrder"
      class="box-item"
      effect="dark"
      content="Showcase gift"
      placement="bottom"
    >
      <el-button
        @click="$emit('pinGift', true)"
        circle
        class="absolute right-2 top-1 z-[8]"
        :class="notFocus ? 'opacity-30' : 'opacity-100'"
        ><el-icon><Star /></el-icon
      ></el-button>
    </el-tooltip>
    <div
      @click="!editingGiftOrder ? $emit('openSlide') : ''"
      class="gift-overlay group block aspect-video w-full overflow-hidden rounded-lg border transition-opacity hover:opacity-60 md:aspect-square"
      :class="[
        notFocus ? 'opacity-30' : 'opacity-100',
        editingGiftOrder ? ' border-gray-500 opacity-90' : ' border-gray-200',
      ]"
    >
      <div
        v-lazy:background-image="
          gift.imgPreview && gift.properties.img_source == 'img'
            ? gift.imgPreview
            : giftImageUrl(gift)
        "
        class="pointer-events-none flex h-full w-full items-center justify-center bg-cover bg-center object-scale-down"
      >
        <div
          v-if="gift.is_funded"
          class="flex h-full w-full items-center justify-center bg-black/50"
        >
          <p class="text-xl font-bold uppercase text-white">Funded</p>
        </div>
      </div>
    </div>
  </div>

  <div
    class="mt-2 flex items-center space-x-2"
    :class="notFocus ? 'opacity-30' : 'opacity-100'"
  >
    <span
      class="pointer-events-none block truncate text-lg font-medium text-gray-900 md:text-xl"
    >
      {{ gift.name }}
    </span>
    <el-tag v-if="admin && gift.draft" round>Draft</el-tag>
  </div>

  <div
    v-if="showPrices"
    class="text-md md:text-md pointer-events-none text-gray-900"
    :class="notFocus ? 'opacity-30' : 'opacity-100'"
  >
    <p v-if="gift.type != 'unlimited'">
      {{
        gift.total_contributions
          ? `${formatCurrency(
              gift.total_contributions_fmt,
              gift.country_code,
              gift.currency
            )}`
          : `${formatCurrency(0, gift.country_code, gift.currency)}`
      }}
      <span class="mt-6 text-xs text-gray-500">
        {{
          `of ${
            gift.amount_fmt
              ? `${formatCurrency(
                  gift.amount_fmt,
                  gift.country_code,
                  gift.currency
                )}`
              : `${formatCurrency(0, gift.country_code, gift.currency)}`
          } funded`
        }}
      </span>
    </p>
    <p v-else-if="gift.type == 'unlimited'" class="text-gray-500">Contribute</p>
    <!-- <p v-else class="text-gray-500">No limit</p> -->
  </div>
</template>

<script>
import { Star } from "@element-plus/icons-vue";
import { Bars3Icon } from "@heroicons/vue/24/outline";

export default {
  components: { Star, Bars3Icon },
  props: ["gift", "showPrices", "admin", "notFocus", "editingGiftOrder"],
  emits: ["pinGift", "openSlide"],
};
</script>