import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    isAuthenticated: null,
    user: null,
    //for affiliate users
    isAffiliateAuthenticated: null,

  }),

  actions: {
    authenticateUser(login, user) {
      this.isAuthenticated = login;
      this.isAffiliateAuthenticated = false;
      this.user = user;
    },

    updateEmailVerified(verified) {
      this.user.email_verified = verified;
    },
    updateEmailBounced(bounced) {
      this.user.email_bounced = bounced;
    },
    updateUserEmail(email) {
      this.user.email = email;
    },
    //for affiliate users
    authenticateAffiliate(login, user) {
      this.isAffiliateAuthenticated = login;
      this.isAuthenticated = false;
      this.user = user;
    },
  },
  persist: true,
});
